import cx from 'classnames';
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PadlockImage from '../../images/PadlockImage';
import InfoBgImage from '../../../images/security-info-background.svg';

import styles from './Security.module.scss';

const slides = [
  {
    title: '環境管制',
    content: '客户挑選我們，我們也幫客戶挑選鄰居，謝絕存放危險品，食物，有異味發出及一切可能影響到你的情況發生。'
  },
  { title: '監控錄影', content: '除了出入系統外，閉路電視安裝在倉庫每個角落，保障你的財產安全。' },
  { title: '氣溫調控', content: '保持恆溫24-27度，確保您的物品長期處於乾爽穩定的環境之中。' },
  { title: '防蟲控制', content: '定期除蟲及檢查倉庫整體情況，以保持倉內環境清潔衛生。' }
];

export default class Security extends Component {
  state = {
    currentItem: 0
  };

  onClickPrev = () => {
    if (this.hasPrev()) {
      this.setState(state => ({ currentItem: state.currentItem - 1 }));
    }
  };

  onClickNext = () => {
    if (this.hasNext()) {
      this.setState(state => ({ currentItem: state.currentItem + 1 }));
    }
  };

  hasPrev() {
    return this.state.currentItem > 0;
  }

  hasNext() {
    return this.state.currentItem < slides.length - 1;
  }

  render() {
    const { currentItem } = this.state;

    const slide = slides[currentItem];

    return (
      <section className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.headline}>採用先進保安系統，為求讓你的物品得到高度保護</div>

          <div className={styles.widget}>
            <div className={styles.image}>
              <PadlockImage />
            </div>

            <div className={styles.slide}>
              <div key={slide.title} className={styles.content}>
                <header>{slide.title}</header>
                <p>{slide.content}</p>
              </div>

              <div className={styles.dots}>
                {slides.map((_, index) => (
                  <div key={index} className={cx(styles.dot, { [styles.active]: currentItem === index })} />
                ))}
              </div>

              {this.hasPrev() && (
                <div className={cx(styles.arrow, styles.prev)} role="button" onClick={this.onClickPrev}>
                  <FontAwesomeIcon fixedWidth icon={['fal', 'chevron-left']} />
                </div>
              )}

              {this.hasNext() && (
                <div className={cx(styles.arrow, styles.next)} role="button" onClick={this.onClickNext}>
                  <FontAwesomeIcon fixedWidth icon={['fal', 'chevron-right']} />
                </div>
              )}

              <div className={styles.infoBg}>
                <InfoBgImage width={null} height={null} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
