import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import styles from './index.module.scss';

const Button = ({ href, to, small, disabled, className, children, ...props }) => {
  let Comp = 'button';
  if (href) {
    Comp = 'a';
  } else if (to) {
    Comp = Link;
  }

  return (
    <Comp
      href={href}
      to={to}
      className={cx(styles.root, className, {
        [styles.small]: small,
        [styles.disabled]: disabled
      })}
      {...props}
    >
      {children}
    </Comp>
  );
};

export default Button;
