import cx from 'classnames';
import { Link } from 'gatsby';
import React, { useMemo, useState } from 'react';
import BodyClassName from 'react-body-classname';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../Button';
import Logo from '../../images/logo-horizontal-white.svg';

import styles from './index.module.scss';

const Nav = ({ fixed, location, showI18n = false, visible = true } = {}) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const locale = useMemo(() => {
    if (location && /^\/en\//.test(location.pathname)) {
      return 'en';
    }
    return 'zh';
  }, [location?.pathname]);

  function toggleMobileNav() {
    setMobileNavOpen(!isMobileNavOpen);
  }

  function closeMobileNav() {
    setMobileNavOpen(false);
  }

  return (
    <div
      className={cx(styles.root, {
        [styles.fixed]: fixed,
        [styles.visible]: visible
      })}
    >
      <BodyClassName className={isMobileNavOpen ? styles.bodyFixed : ''} />

      <div className={cx(styles.overlay, { [styles.open]: isMobileNavOpen })} />
      <div className={cx(styles.mobileButton, { [styles.close]: isMobileNavOpen })} onClick={toggleMobileNav}>
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
      </div>
      <div className={cx(styles.mobileNav, { [styles.open]: isMobileNavOpen })}>
        <ul className={styles.items}>
          <li>
            <Link to="/book" activeClassName={styles.selected} onClick={closeMobileNav}>
              立即訂倉
            </Link>
          </li>
          <li>
            <Link to="/#pricing" activeClassName={styles.selected} onClick={closeMobileNav}>
              服務收費
            </Link>
          </li>
          <li>
            <Link to="/locations" activeClassName={styles.selected} onClick={closeMobileNav}>
              分店網絡
            </Link>
          </li>
          <li className={styles.divider} />
          <li className={styles.small}>
            <Link to="/retrieve" activeClassName={styles.selected} onClick={closeMobileNav}>
              預約提取
            </Link>
          </li>
          <li className={styles.small}>
            <Link to="/services" activeClassName={styles.selected} onClick={closeMobileNav}>
              服務流程
            </Link>
          </li>
          <li className={styles.small}>
            <Link to="/gallery" activeClassName={styles.selected} onClick={closeMobileNav}>
              存倉環境
            </Link>
          </li>
          <li className={styles.small}>
            <Link to="/about" activeClassName={styles.selected} onClick={closeMobileNav}>
              關於我們
            </Link>
          </li>
          <li className={styles.small}>
            <Link to="/promo" activeClassName={styles.selected} onClick={closeMobileNav}>
              最新優惠
            </Link>
          </li>
          <li className={styles.small}>
            <Link to="/franchising" activeClassName={styles.selected} onClick={closeMobileNav}>
              加入儲方
            </Link>
          </li>
        </ul>
        <ul className={styles.items}>
          <li>
            <a href="tel:21113113" onClick={closeMobileNav}>
              <FontAwesomeIcon fixedWidth icon={['fal', 'phone']} />
              <span>2111 3113</span>
            </a>
          </li>
        </ul>
        {showI18n && (
          <ul className={cx(styles.items, styles.locale)}>
            <li className={styles.small}>
              <Link to="/en/juppuk-new-years" className={cx(locale === 'en' && styles.selected)}>
                EN
              </Link>
            </li>
            <li className={styles.small}>
              <Link to="/zh/juppuk-new-years" className={cx(locale === 'zh' && styles.selected)}>
                中文
              </Link>
            </li>
          </ul>
        )}
      </div>

      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Logo width={null} height={null} />
        </Link>

        <div className={styles.nav}>
          <ul className={styles.items}>
            <li>
              <Link to="/#pricing" activeClassName={styles.selected}>
                服務收費
              </Link>
            </li>
            <li>
              <Link to="/services" activeClassName={styles.selected}>
                服務流程
              </Link>
            </li>
            <li>
              <Link to="/about" activeClassName={styles.selected}>
                關於我們
              </Link>
            </li>
            <li>
              <Link to="/locations" activeClassName={styles.selected}>
                分店網絡
              </Link>
            </li>
            <li>
              <Link to="/gallery" activeClassName={styles.selected}>
                存倉環境
              </Link>
            </li>
            {!showI18n && (
              <li>
                <Link to="/promo" activeClassName={styles.selected}>
                  最新優惠
                </Link>
              </li>
            )}
            <li>
              <Link to="/retrieve" activeClassName={styles.selected}>
                預約提取
              </Link>
            </li>
          </ul>
          {showI18n && (
            <ul className={cx(styles.items, styles.locale)}>
              <li>
                <Link to="/en/juppuk-new-years" className={cx(locale === 'en' && styles.selected)}>
                  EN
                </Link>
              </li>
              <li>
                <Link to="/zh/juppuk-new-years" className={cx(locale === 'zh' && styles.selected)}>
                  中文
                </Link>
              </li>
            </ul>
          )}
          <ul className={styles.items}>
            <li>
              <a href="tel:21113113">
                <FontAwesomeIcon fixedWidth icon={['fal', 'phone']} />
                <span>2111 3113</span>
              </a>
            </li>
          </ul>
          <div className={styles.button}>
            <Button small to="/book">
              立即訂倉
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
