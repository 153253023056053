import React from 'react';

import StorageUnitImage from '../../images/StorageUnitImage';

import styles from './Features.module.scss';

const Features = () => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <StorageUnitImage />
      </div>
      <div className={styles.content}>
        <section>
          <header>合法安心</header>
          <p>我們除了提供傳統迷你倉同樣的儲存空間及彈性，更提供合法安心的儲存環境，安全穩妥的長久為您保管您的物品。</p>
        </section>
        <section>
          <header>彈性收費</header>
          <p>
            保留傳統迷你倉的計費方式外，同時提供多種不同尺碼的優惠流動儲存倉，在傳統的基礎上靈活變通，貼合所有私人或公司的不同儲物需要。
          </p>
        </section>
        <section>
          <header>與別不同</header>
          <p>
            皇牌產品衣櫃倉及書櫃倉，衣櫃倉跟其他公司提供的「特色倉」絕不能相提並論，並非單單加入鐵支供你掛衣服那麼簡單，而是真正讓您感覺置身私人衣櫃，而書櫃倉更可令你對迷你倉有全新的體驗及見解。
          </p>
        </section>
      </div>
    </div>
  </div>
);

export default Features;
