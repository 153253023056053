import cx from 'classnames';
import React from 'react';

import styles from './index.module.scss';

const ServiceItem = ({ id, name, area, Image, price, dark, showBookNow }) => (
  <div className={cx(styles.root, { [styles.dark]: dark })}>
    <header>{name}</header>
    <div className={styles.area}>{area}</div>
    <div className={styles.imageWrapper}>
      <div className={cx(styles.image, `product-${id}`)}>
        <Image />
      </div>
    </div>
    <div className={styles.button}>
      {showBookNow && <div className={styles.book}>立即訂倉</div>}
      <div className={styles.price}>
        <div className={styles.symbol}>$</div>
        <div className={styles.amount}>{price}</div>
        <div className={styles.above}>起</div>
      </div>
    </div>
  </div>
);

export default ServiceItem;
