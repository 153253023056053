import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Image from '../images/StaffPromoImage';

import styles from './index.module.scss';

const PromoPopup = ({ visible, onClose }) => (
  <div className={cx(styles.root, { [styles.visible]: visible })}>
    <div className={styles.image}>
      <Image />
    </div>
    <div className={styles.text}>
      <div>客戶推薦獎賞</div>
      <div className={styles.price}>
        <div className={styles.symbol}>$</div>
        <div className={styles.amount}>300</div>
        <div>
          <div className={styles.cash}>現金</div>
          <div className={styles.coupon}>或超市禮卷</div>
        </div>
      </div>
    </div>
    <Link to="/promo" className={styles.button}>
      了解更多優惠
    </Link>
    <div role="button" className={styles.close} onClick={onClose}>
      <FontAwesomeIcon fixedWidth icon={['fal', 'times-circle']} />
    </div>
  </div>
);

export default PromoPopup;
