import React from 'react';

import Button from '../../Button';
import HeroImage from '../../images/ServiceHeroImage';

import styles from './Services.module.scss';

const Services = () => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <header>嶄新營運模式</header>
        <p>
          除了傳統的自存倉以外，我們更設有新式的流動倉，價格更為相宜，以迎合不同客戶的需求。不論您有任何儲存需要，我們都能為您提供最貼心的服務。
        </p>
        <Button to="/services">了解更多</Button>
      </div>
      <div className={styles.image}>
        <HeroImage />
      </div>
    </div>
  </div>
);

export default Services;
