import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

const ProductItem = ({ Image, onChange, minQuantity, maxQuantity, name, price, quantity = 1 }) => {
  return (
    <div className={styles.root}>
      <div className={styles.name}>{name}</div>

      <div className={styles.imageWrapper}>
        <Image />
      </div>

      <div className={styles.controls}>
        <button
          type="button"
          className={cx(styles.button)}
          disabled={quantity <= minQuantity}
          onClick={() => onChange(quantity - 1)}
        >
          <FontAwesomeIcon fixedWidth icon={['fal', 'minus']} />
        </button>
        <div className={styles.quantity}>{quantity}</div>
        <button
          type="button"
          className={cx(styles.button)}
          disabled={quantity >= maxQuantity}
          onClick={() => onChange(quantity + 1)}
        >
          <FontAwesomeIcon fixedWidth icon={['fal', 'plus']} />
        </button>
      </div>

      <div className={styles.price}>
        <div className={styles.symbol}>$</div>
        <div className={styles.amount}>{price}</div>
      </div>
    </div>
  );
};

ProductItem.propTypes = {
  Image: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  minQuantity: PropTypes.number.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired
};

export default ProductItem;
