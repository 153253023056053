import cx from 'classnames';
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Photo01 from '../../images/Testimonial01Image';
import Photo02 from '../../images/Testimonial02Image';
import Photo03 from '../../images/Testimonial03Image';
import Photo04 from '../../images/Testimonial04Image';
import Photo05 from '../../images/Testimonial05Image';
import Photo06 from '../../images/Testimonial06Image';

import styles from './Testimonials.module.scss';

const testimonials = [
  {
    name: '孫慧雪',
    title: '著名藝人',
    content: '「為了迎接BB的到來，面對更換多餘傢俬怎麼辦？我選擇了Roomyr，佢地嘅迷你倉環境令我非常安心！」',
    Image: Photo01
  },
  {
    name: '傑傑',
    title: '商台DJ',
    content: '「在朋友介紹下開始使用Roomyr，其設施齊全整潔，保安及特別是客戶服務都令我十分滿意。」',
    Image: Photo02
  },
  {
    name: '萬寧妹妹一家',
    title: '',
    content:
      '「阿女工作紀錄係佢成長嘅見證，我地一家都想好好保存呢啲回憶。除此之外，因為工作需要存放較多衣服，之前使用的迷你倉，雖然整潔，但過左一段時間，我發現衣服上有陣陣油漆味，原來我嘅隣居係裝修公司！參觀Roomyr嘅時候，客服員細心了解清楚我將會存放嘅物品係衣服之後，就推薦我用佢地嘅衣櫃倉，除左入面有小型傢俬比我用之外，佢地管理倉庫同選擇客户嘅規定都令我好放心。」',
    Image: Photo03
  },
  {
    name: 'Peony Chan',
    title: 'SDM爵士芭蕾舞學院校長',
    content:
      '「愛書的我租用書櫃倉多年，倉𥚃提供的迷你傢俬令我的愛書收藏得非常整齊。現在需要跟那本書”重聚” ，再不需要像以往一樣愚公移山才能相見。」',
    Image: Photo04
  },
  {
    name: 'Marco',
    title: '商台DJ',
    content:
      '「之前用開嗰間迷你倉結業，令我醒覺到要搵一間有信譽兼合法嘅迷你倉比我長期使用係好重要。由搬倉、入新倉、辦理手續、付款，都安排得好細心又有效率，我依家好放心！交比你 Roomyr！」',
    Image: Photo05
  },
  {
    name: 'Karen Leung',
    title: 'Forest by K 創辦人',
    content:
      '「作為高品花店，已不止於供應高質花品擺設，Forest by K每星期也會舉辦不同教班和商業Event。當中涉及到限時使用的用品，我們通通都選擇存放在迷你倉，保持店鋪的空間感令客戶可舒適地欣賞及選擇花品。」',
    Image: Photo06
  }
];

export default class Testimonials extends Component {
  state = {
    currentItem: 0
  };

  onClickPrev = () => {
    if (this.hasPrev()) {
      this.setState(state => ({ currentItem: state.currentItem - 1 }));
    }
  };

  onClickNext = () => {
    if (this.hasNext()) {
      this.setState(state => ({ currentItem: state.currentItem + 1 }));
    }
  };

  hasPrev() {
    return this.state.currentItem > 0;
  }

  hasNext() {
    return this.state.currentItem < testimonials.length - 1;
  }

  renderItem = (item, index) => {
    const { currentItem } = this.state;

    return (
      <div
        key={item.name}
        className={cx(styles.testimonial, { [styles.active]: currentItem === index }, `testimonial-${index + 1}`)}
      >
        <div className={styles.person}>
          <div className={styles.image}>
            <item.Image />
          </div>
          <div className={styles.name}>{item.name}</div>
          <div>{item.title}</div>
        </div>
        <div className={styles.content}>{item.content}</div>
      </div>
    );
  };

  render() {
    const { currentItem } = this.state;

    return (
      <section className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.testimonials} style={{ transform: `translateX(${currentItem * -100}%)` }}>
            {testimonials.map(this.renderItem)}
          </div>
          <div className={styles.controls}>
            {this.hasPrev() ? (
              <div className={cx(styles.arrow, styles.prev)} role="button" onClick={this.onClickPrev}>
                <FontAwesomeIcon fixedWidth icon={['fal', 'long-arrow-left']} />
              </div>
            ) : (
              <div />
            )}

            {this.hasPrev() && (
              <div className={cx(styles.arrow, styles.prev, styles.mobile)} role="button" onClick={this.onClickPrev}>
                <FontAwesomeIcon fixedWidth icon={['fal', 'chevron-left']} />
              </div>
            )}

            <div className={styles.dots}>
              {testimonials.map((_, index) => (
                <div key={index} className={cx(styles.dot, { [styles.active]: currentItem === index })} />
              ))}
            </div>

            {this.hasNext() ? (
              <div className={cx(styles.arrow, styles.next)} role="button" onClick={this.onClickNext}>
                <FontAwesomeIcon fixedWidth icon={['fal', 'long-arrow-right']} />
              </div>
            ) : (
              <div />
            )}

            {this.hasNext() && (
              <div className={cx(styles.arrow, styles.next, styles.mobile)} role="button" onClick={this.onClickNext}>
                <FontAwesomeIcon fixedWidth icon={['fal', 'chevron-right']} />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
