import { Link } from 'gatsby';
import React, { useState } from 'react';

import products from '../../../data/products';

import ServiceItem from '../../ServiceItem';

import styles from './Pricing.module.scss';

const Pricing = () => {
  const [hoverItem, setHoverItem] = useState(-1);

  return (
    <section className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <header>服務收費</header>
          <p className={styles.description}>
            致力提供更多元化的服務切合您的需要，各款迷你儲存倉價錢均列明於網頁上，價格透明，收費公道。
          </p>
          <div className={styles.disclaimer}>不同地區價格或有差異</div>
        </div>
        <div className={styles.productsWrapper}>
          <div className={styles.products}>
            {products.map(product => (
              <Link
                to={`/book?product=${product.id}`}
                key={product.id}
                className={styles.product}
                onMouseOver={() => setHoverItem(product.id)}
                onMouseOut={() => setHoverItem(-1)}
              >
                <ServiceItem {...product} dark={product.id === hoverItem} showBookNow />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
