import React, { useCallback, useState } from 'react';
import ProductItem from '../../ProductItem';

import Button from '../../Button';
import { Select } from '../../FormControls';
import ProductSmallImage from '../../images/ProductSmallImage';
import ProductPadlockImage from '../../images/ProductPadlockImage';

import styles from './Products.module.scss';

const Products = () => {
  const [isSubmitting] = useState(false);
  const [warehouses] = useState([]);
  const [warehouseId, setWarehouseId] = useState();
  const [boxQuantity, setBoxQuantity] = useState(1);
  const [lockQuantity, setLockQuantity] = useState(1);

  const submit = useCallback(async () => {}, [warehouseId, boxQuantity, lockQuantity]);

  return (
    <section className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <header>購買產品</header>
          <p className={styles.description}>
            為配合您更好的整理儲存空間，我們設計了一系列的產品，貼合您不同的儲存需要。歡迎在此訂購產品，我們將安排專人與您預約時間，安排所需產品送達所選分店，以便閣下使用。
          </p>
        </div>
        <div className={styles.products}>
          <ProductItem
            Image={ProductSmallImage}
            name="紙箱"
            price="15"
            minQuantity={0}
            maxQuantity={10}
            quantity={boxQuantity}
            onChange={setBoxQuantity}
          />
          <ProductItem
            Image={ProductPadlockImage}
            name="掛鎖"
            price="50"
            minQuantity={0}
            maxQuantity={10}
            quantity={lockQuantity}
            onChange={setLockQuantity}
          />
        </div>
        <div className={styles.warehouse}>
          <Select label="自取分店" onChange={e => setWarehouseId(e.target.value)} value={warehouseId}>
            {warehouses.map(w => (
              <option key={w.id} value={w.id}>
                {w.name}
              </option>
            ))}
          </Select>
        </div>
        <div className={styles.submit}>
          <Button disabled={isSubmitting || (boxQuantity <= 0 && lockQuantity <= 0)} onClick={submit}>
            立即付款
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Products;
