import '../../styles/global.scss';

import cookies from 'js-cookie';
import cx from 'classnames';
import React, { Component } from 'react';

import Nav from '../Nav';
import Footer from '../Footer';
import PromoPopup from '../PromoPopup';

import styles from './index.module.scss';

const PROMO_COOKIE = 'closed_promo';

export default class Layout extends Component {
  state = {
    isNavFixed: false,
    isPromoVisible: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);

    if (!this.props.disablePromoPopup && !cookies.get(PROMO_COOKIE)) {
      window.setTimeout(() => this.setState({ isPromoVisible: true }), 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    this.setState({ isNavFixed: window.scrollY >= 1 });
  };

  closePromo = () => {
    cookies.set(PROMO_COOKIE, true);
    this.setState({ isPromoVisible: false });
  };

  render() {
    const { showNav = true, navAlwaysFixed, className, children } = this.props;
    const { isNavFixed, isPromoVisible } = this.state;

    return (
      <div className={cx(styles.root, className)}>
        <Nav
          visible={showNav}
          fixed={navAlwaysFixed || isNavFixed}
          location={this.props.location}
          showI18n={this.props.showI18n}
        />
        {children}
        <Footer />
        <PromoPopup visible={isPromoVisible} onClose={this.closePromo} />
      </div>
    );
  }
}
