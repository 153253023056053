import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '../../Button';
import { cleanTelStr, Input, Select, TelInput } from '../../FormControls';

import styles from './Appointment.module.scss';

const schema = yup.object().shape({
  name: yup.string().required(),
  tel: yup
    .string()
    .required()
    .test('is-tel-valid', 'not valid', val => cleanTelStr(val).length === 8),
  warehouseId: yup.string().required(),
  date: yup.string().required()
});

const resolver = yupResolver(schema);

export default function Appointment() {
  const [success] = useState(false);
  const [dates, setDates] = useState([]);
  const [warehouses] = useState([]);

  const { register, formState, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      tel: '',
      warehouseId: null,
      date: null
    },
    resolver
  });

  const submit = useCallback(async () => {}, []);

  useEffect(() => {
    const allowedDates = [1, 2, 3, 4, 5, 6, 7].map((_, day) =>
      DateTime.local()
        .startOf('day')
        .plus({ day })
        .toFormat('yyyy/L/d')
    );

    setDates(allowedDates);
    setValue('date', allowedDates[0]);
  }, [setDates, setValue]);

  if (success) {
    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div>
            <h2>感謝您！我們很快便會聯繫您。</h2>
            <p>如您有任何疑問，請隨時致電2111 3113與我們聯繫。</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div>
          <h2 className={styles.title}>想立即體驗優越儲存環境？</h2>
          <p>
            請填妥以下簡單預約資料，我們將立即安排專員在閣下指定時間帶領您參觀指定分店，讓您親身體驗我們優越的儲存環境與服務！
          </p>
          <form onSubmit={handleSubmit(submit)}>
            <div className={styles.form}>
              <Select name="warehouseId" label="分店" {...register('warehouseId')}>
                {warehouses.map(warehouse => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </option>
                ))}
              </Select>
              <Input name="name" label="姓名" {...register('name')} />
              <TelInput name="tel" label="電話" {...register('tel')} />
              <Select name="date" label="預約日期" {...register('date')}>
                {dates.map(date => (
                  <option key={date} value={date}>
                    {date}
                  </option>
                ))}
              </Select>
              <Button type="submit" tabIndex={0} disabled={!formState.isValid || formState.isSubmitting}>
                提交
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
