import React from 'react';

import Map from '../../../images/hong-kong-map.svg';

import styles from './Locations.module.scss';

const Locations = () => (
  <section className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.map}>
        <Map width={null} height={null} />
      </div>
      <div className={styles.content}>
        <header>強大儲存網絡</header>
        <p>Roomyr 擁有龐大儲存網絡及經驗豐富的管理層，是您最可靠安心的選擇。</p>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <div className={styles.num}>1,962</div>
            <div className={styles.name}>儲存單位</div>
          </div>
          <div className={styles.stat}>
            <div className={styles.num}>12</div>
            <div className={styles.name}>網絡分店</div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Locations;
