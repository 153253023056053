import React, { useState, useEffect } from 'react';

import Layout from '../components/Layout';
import HomeNav from '../components/HomeNav';
import SEO from '../components/SEO';

import Lead from '../components/pages/home/Lead';
import Appointment from '../components/pages/home/Appointment';
import Enquire from '../components/pages/home/Enquire';
import Features from '../components/pages/home/Features';
import Locations from '../components/pages/home/Locations';
import Services from '../components/pages/home/Services';
import Pricing from '../components/pages/home/Pricing';
import Products from '../components/pages/home/Products';
import Security from '../components/pages/home/Security';
import Testimonials from '../components/pages/home/Testimonials';
import Charity from '../components/pages/home/Charity';

import styles from './index.module.scss';

const IndexPage = props => {
  const [showNav, setShowNav] = useState(false);
  useEffect(() => {
    function onScroll() {
      setShowNav(window.scrollY > 120);
    }

    window.addEventListener('scroll', onScroll);

    return function cleanup() {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <Layout location={props.location} showNav={showNav}>
      <div className={styles.root}>
        <SEO
          title="租迷你倉價錢收費 | 儲存倉出租價格 | 香港自存倉租倉費用"
          description="Roomyr－讓你儲物無難度，提供嶄新特色儲存倉庫：衣櫃倉、書櫃倉、精品倉等，用家可按需要選擇租用合適的迷你倉，收費公道，讓空間更靈活，活得更自在。"
        />
        <HomeNav />
        <Lead />
        <Enquire gray />
        <Locations />
        <Services />
        <Security />
        <div id="pricing">
          <Pricing />
        </div>
        <Products />
        <Appointment />
        <Features />
        <Testimonials />
        <Charity />
      </div>
    </Layout>
  );
};

export default IndexPage;
