import cx from 'classnames';
import React, { Component } from 'react';

import Button from '../../Button';
import { cleanTelStr, Input, TelInput } from '../../FormControls';
import BoxImage from '../../images/BoxSingleImage';

import styles from './Enquire.module.scss';

export default class Enquire extends Component {
  state = {
    name: '',
    tel: '',

    isSaving: false,
    isSuccess: false
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  submit = async () => {};

  shouldAllowSubmit() {
    const { name, tel } = this.state;
    return !!(name.trim() && cleanTelStr(tel).length === 8);
  }

  renderSuccess() {
    return (
      <div className={cx(styles.root, { [styles.gray]: this.props.gray })}>
        <div className={styles.wrapper}>
          <div>
            <h2>感謝您！我們很快便會聯繫您。</h2>
            <p>如您有任何疑問，請隨時致電2111 3113與我們聯繫。</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { name, tel, isSaving, isSuccess } = this.state;

    if (isSuccess) {
      return this.renderSuccess();
    }

    return (
      <div className={cx(styles.root, { [styles.gray]: this.props.gray })}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>想立即獲取報價？</h2>
            <p>
              請輸入以下簡單資料，我們的服務主任將於24小時內來電了解您的需要，並為您推薦最合適的儲存方案。
              您亦可立即致電 2111 3113 聯絡我們。
            </p>
            <div className={styles.form}>
              <Input name="name" label="姓名" value={name} onChange={this.onChange} />
              <TelInput name="tel" label="電話" value={tel} disabled={isSaving} onChange={this.onChange} />
              <Button tabIndex={0} disabled={!this.shouldAllowSubmit() || isSaving} onClick={this.submit}>
                聯絡我們
              </Button>
            </div>
          </div>
          <div className={styles.image}>
            <BoxImage />
          </div>
        </div>
      </div>
    );
  }
}
