import ProductSmallImage from '../components/images/ProductSmallImage';
import ProductMediumImage from '../components/images/ProductMediumImage';
import ProductLargeImage from '../components/images/ProductLargeImage';
import ProductDocumentImage from '../components/images/ProductDocumentImage';
import ProductWardrobeImage from '../components/images/ProductWardrobeImage';

export default [
  {
    id: 1,
    name: '小型倉',
    Image: ProductSmallImage,
    area: '5 - 15 平方尺',
    price: '390',
    description:
      '合適個人存放家居物品，季節性衣服，鞋，玩具，兒童物品，書籍，收藏品，單車，滑雪板，文件，大中小行李喼。',
    popularSizes: ['12 平方尺 (4尺 x 3尺)', '8 平方尺 (4尺 x 2尺)']
  },
  {
    id: 2,
    name: '中型倉',
    Image: ProductMediumImage,
    area: '16 - 29 平方尺',
    price: '858',
    description:
      '合適小家庭使用，其中16，20平方尺為客戶普遍採用，空間可存放家居物品，季節性衣服，鞋，玩具，兒童物品，書籍，收藏品，單車，滑雪板，文件，大中小行李喼，桌子，椅子，小型櫃，工具，單車，手推車，商用物品，大型油畫，綿胎披。',
    popularSizes: ['16 平方尺 (4尺 x 5尺)', '20 平方尺 (4尺 x 5尺)', '24 平方尺 (4尺 x 6尺)']
  },
  {
    id: 3,
    name: '大型倉',
    Image: ProductLargeImage,
    area: '30 - 200 平方尺',
    price: '1,408',
    description:
      '合適家居裝修暫存及商業用途使用，空間可存放家居物品，季節性衣服，鞋，玩具，兒童物品，書籍，收藏品，單車，滑雪板，文件，大中小行李喼，桌子，椅子，小型櫃，工具，單車，手推車，商用物品，大型油畫，綿胎披，梳化，衣櫃，床鋪，電視機櫃，洗衣機，雪櫃等。商用客戶可存放。',
    popularSizes: ['32 平方尺 (4尺 x 8尺)', '40 平方尺 (4尺 x 10尺)']
  },
  {
    id: 4,
    name: '文件倉',
    Image: ProductDocumentImage,
    area: '12 - 16 平方尺',
    price: '1,288',
    description:
      '合適個人存放書籍，收藏品，玩具，專心設計主要為收藏衣服為目的，內設預設書架及搪櫃方便收藏書本或其收藏品，打開倉已能立即閱你心愛書本及觀賞你既收藏品。',
    popularSizes: ['12 平方尺 (4尺 x 3尺)', '16 平方尺 (4尺 x 4尺)']
  },
  {
    id: 5,
    name: '衣櫃倉',
    Image: ProductWardrobeImage,
    area: '12 - 16 平方尺',
    price: '1,288',
    description:
      '合適個人存放季節性衣服，鞋，專心設計主要為收藏衣服為目的，內設預設傢具方便收藏衣服，需拿取衣服更方便稱心，更能好好保護你既衣服。',
    popularSizes: ['12 平方尺 (4尺 x 3尺)', '16 平方尺 (4尺 x 4尺)']
  }
];
