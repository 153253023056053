import React from 'react';

import Button from '../../Button';

import Logo from '../../../images/chf-logo.svg';
import Boy from '../../../images/boy.svg';
import Girl from '../../../images/girl.svg';

import styles from './Charity.module.scss';

const Charity = () => (
  <section className={styles.root}>
    <div className={styles.text}>
      <img className={styles.logo} src={Logo} alt="Children's Heart Foundation" />
      <header>
        <div>身體健康非必然</div>
        <div>為每一下心跳奮鬥</div>
      </header>
      <p>
        我們承諾把部分收益作慈善用途，向香港兒童心臟基金會購買禮品並轉贈客戶，為心臟病童出一分力。現凡惠顧Roomyr之客戶均可免費獲取一份由心臟病童設計之禮品。請支持香港兒童心臟基金會！您亦可另行於網上捐款。
      </p>
      <Button className={styles.button} href="http://www.childheart.org.hk/tr/contentpage.asp?id=13" target="_blank">
        了解更多
      </Button>
    </div>
    <div className={styles.children}>
      <img src={Girl} alt="girl" />
      <img src={Boy} alt="boy" />
    </div>
  </section>
);

export default Charity;
