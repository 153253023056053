import cx from 'classnames';
import React, { forwardRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import composeRefs from '@seznam/compose-react-refs';

import styles from './index.module.scss';

export const Input = forwardRef(({ className, label, name, onBlur, onFocus, ...props }, ref) => {
  const [focused, setFocused] = useState(false);

  function handleBlur(e) {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  }

  function handleFocus(e) {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  }

  return (
    <div className={cx(styles.root, className)}>
      {!!label && (
        <label htmlFor={name} className={cx({ [styles.active]: focused })}>
          {label}
        </label>
      )}
      <input
        ref={ref}
        className={styles.control}
        type="text"
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...props}
      />
    </div>
  );
});

export const TelInput = forwardRef(({ disabled, label, name, value, onChange }, ref) => (
  <MaskedInput
    mask={['+', '8', '5', '2', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
    name={name}
    placeholder="+852"
    value={value}
    disabled={disabled}
    onChange={onChange}
    render={(innerRef, inputProps) => <Input ref={composeRefs(innerRef, ref)} {...inputProps} label={label} />}
  />
));

export const Select = forwardRef(({ className, label, name, onBlur, onFocus, children, ...props }, ref) => {
  const [focused, setFocused] = useState(false);

  function handleBlur(e) {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  }

  function handleFocus(e) {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  }

  return (
    <div className={cx(styles.root, className)}>
      {!!label && (
        <label htmlFor={name} className={cx({ [styles.active]: focused })}>
          {label}
        </label>
      )}
      <select ref={ref} className={styles.control} name={name} onBlur={handleBlur} onFocus={handleFocus} {...props}>
        {children}
      </select>
    </div>
  );
});

export function cleanTelStr(tel) {
  return tel ? tel.replace(/(\+852|_| )/g, '') : '';
}
