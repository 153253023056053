import { Link } from 'gatsby';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StaffHelpImage from '../images/StaffHelpImage';

import styles from './index.module.scss';

const Footer = () => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.help}>
          <div className={styles.image}>
            <StaffHelpImage />
          </div>
          <div>無論您有任何儲存問題，不要忘記有我們隨時為您提供協助</div>
        </div>

        <ul className={styles.contact}>
          <li>
            <a href="mailto:info@roomyr.com">
              <FontAwesomeIcon fixedWidth icon={['fal', 'envelope']} />
              <span>電郵聯絡</span>
            </a>
          </li>
          <li>
            <a href="https://wa.me/85263572288" target="_blank" rel="noreferrer noopener">
              <FontAwesomeIcon fixedWidth icon={['fab', 'whatsapp']} />
              <span>6357 2288</span>
            </a>
          </li>
          <li>
            <a href="tel:21113113">
              <FontAwesomeIcon fixedWidth icon={['fal', 'phone']} />
              <span>2111 3113</span>
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.misc}>
        <div>
          <div className={styles.links}>
            <Link to="/franchising">加入儲方</Link>
            <Link to="/terms-conditions">條款及細則</Link>
            <Link to="/privacy-policy">私隱政策</Link>
            <Link to="/faq">常見問題</Link>
          </div>
          <div className={styles.social}>
            <a href="https://www.facebook.com/roomyrstorage/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon fixedWidth icon={['fab', 'facebook-square']} />
            </a>
          </div>
        </div>
        <div>
          <div
            className={styles.copyright}
          >{`© ${new Date().getFullYear()} BDSJ Curated Limited. All rights reserved.`}</div>
          <div className={styles.oopstypo}>
            Designed by{' '}
            <a href="https://oopstypo.com" target="_blank" rel="noopener noreferrer nofollow">
              Oops Typo
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
