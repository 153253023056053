import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../Button';
import Hero01Image from '../../images/MainHero01Image';

import styles from './Lead.module.scss';

const Lead = () => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.lead}>
        <h1>
          儲存有方
          <br />
          新一代特色迷你倉
        </h1>
        <p>
          擁有多年出租迷你倉經驗，我們為您提供一站式儲存及運輸服務，我們的空間估算師會為您解答任何關於儲存的問題，讓您安心、放心。
        </p>
        <Button to="/book">立即訂倉</Button>
        <a href="tel:+85221113113" className={styles.tel}>
          <FontAwesomeIcon fixedWidth icon={['fal', 'phone']} />
          <span>2111 3113</span>
        </a>
      </div>
      <div className={styles.hero}>
        <Hero01Image />
      </div>
    </div>
  </div>
);

export default Lead;
