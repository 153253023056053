import { Link } from 'gatsby';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './index.module.scss';
import Logo from '../../images/logo-vertical-white.svg';

const HomeNav = () => (
  <div className={styles.root}>
    <div className={styles.logo}>
      <Logo width="100%" height={null} />
    </div>
    <div className={styles.wrapper}>
      <ul className={styles.items}>
        <li>
          <Link to="/#pricing" activeClassName={styles.selected}>
            服務收費
          </Link>
        </li>
        <li>
          <Link to="/services" activeClassName={styles.selected}>
            服務流程
          </Link>
        </li>
        <li>
          <Link to="/about" activeClassName={styles.selected}>
            關於我們
          </Link>
        </li>
        <li>
          <Link to="/locations" activeClassName={styles.selected}>
            分店網絡
          </Link>
        </li>
      </ul>
      <ul className={styles.items}>
        <li>
          <Link to="/gallery" activeClassName={styles.selected}>
            存倉環境
          </Link>
        </li>
        <li>
          <Link to="/promo" activeClassName={styles.selected}>
            最新優惠
          </Link>
        </li>
        <li>
          <Link to="/retrieve" activeClassName={styles.selected}>
            預約提取
          </Link>
        </li>
        <li>
          <a href="tel:21113113">
            <FontAwesomeIcon fixedWidth icon={['fal', 'phone']} />
            <span>2111 3113</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default HomeNav;
